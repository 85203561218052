import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import HeadTitle from '../../../components/HeadTitle/HeadTitle'
import { Button, Grid, Switch } from '@material-ui/core'
import { getExternalCompanies, getPdpsByCompanie } from '../../../containers/DataContainer'
import { useCookies } from 'react-cookie'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import SearchExternalCompanies from './SearchExternalCompanies.js'
import SearchIcon from '@material-ui/icons/Search'
import DataTable from '../../../components/NewDataTable/NewDataTable.js'
import i18next from 'i18next'
import moment from 'moment'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
	page: {
		paddingLeft: theme.spacing.unit * 10,
		paddingRight: theme.spacing.unit * 10,
		paddingBottom: theme.spacing.unit * 4,
	},
}))

function PdpList() {
	const classes = useStyles()
	const { t } = useTranslation(['reports', 'user'])
	const [cookies] = useCookies()
	const [externalCompanies, setExternalCompanies] = useState([])
	const [selectedExternalCompany, setSelectedExternalCompany] = useState()
	const [language, setLanguage] = useState(1)
	const [openSearchCompanies, setOpenSearchCompanies] = useState(false)
	const [pdps, setPdps] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [switchCheck, setSwitchCheck] = useState(false)

	useEffect(() => {
		switch (i18next.language) {
			case 'fr':
				setLanguage(1)
				break
			case 'en':
				setLanguage(2)
				break
			default:
				setLanguage(1)
				break
		}
	})

	useEffect(() => {
		getExternalCompanies(cookies[PLATFORM_ID_COOKIE], (res, err) => {
			if (err) return
			if (res) {
				setExternalCompanies(res.data)
			}
		})
	}, [])

	useEffect(() => {
		if (selectedExternalCompany) {
			setIsLoading(true)
			getPdpsByCompanie(
				cookies[PLATFORM_ID_COOKIE],
				selectedExternalCompany.entId,
				language,
				switchCheck,
				(res, err) => {
					if (err) return
					if (res) {
						setIsLoading(false)
						setPdps(res.data)
					}
				},
			)
		}
	}, [selectedExternalCompany])

	const OpenFormCompany = () => {
		setOpenSearchCompanies(true)
	}
	const CloseFormCompany = () => {
		setOpenSearchCompanies(false)
	}
	const handleSwitchClick = () => {
		setSwitchCheck(!switchCheck)
	}
	return (
		<>
			<HeadTitle title={t('reports:pdp_list')} link="/reports" />
			<div className={classes.page}>
				<Grid container>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="secondary"
							aria-label="Search"
							onClick={OpenFormCompany}
						>
							<SearchIcon className={classes.extendedIcon} />
							{t('reports:pdp_list_search')}
						</Button>
						<Switch
							checked={switchCheck}
							size="normal"
							name="notifIsActive"
							onClick={handleSwitchClick}
						/>
						{t('reports:includeAllPdp')}
						<SearchExternalCompanies
							open={openSearchCompanies}
							companyList={externalCompanies}
							setClose={CloseFormCompany}
							setSelectedValue={setSelectedExternalCompany}
						/>
					</Grid>
				</Grid>
				<Grid container className={classes.marginUpDown}>
					<Grid>
						{pdps.length !== 0 && (
							<DataTable
								title={selectedExternalCompany?.entName}
								data={pdps.data}
								columns={pdps.columns}
								options={{
									...pdps.options,
									downloadOptions: {
										...pdps.options.downloadOptions,
										filterOptions: {
											...pdps.options.downloadOptions.filterOptions,
											useDisplayedColumnsOnly: false,
										},
									},
								}}
								isLoading={isLoading}
								customColumns={[
									{
										name: 'rpdStart',
										component: (value) => moment(value).format('DD/MM/YYYY'),
									},
									{
										name: 'rpdEnd',
										component: (value) => moment(value).format('DD/MM/YYYY'),
									},
									{
										name: 'isPrincipalCompany',
										component: (value) => (
											<div style={{ textAlign: 'center' }}>
												{value ? <CheckIcon /> : <CloseIcon />}
											</div>
										),
									},
									{
										name: 'isSubContractingCompany',
										component: (value) => (
											<div style={{ textAlign: 'center' }}>
												{value ? <CheckIcon /> : <CloseIcon />}
											</div>
										),
									},
									{
										name: 'entSiret',
										component: (value) => `"${value}"`,
									},
								]}
								customLabels={[
									{
										name: 'pdpNumber',
										label: t('user:numero_pdp'),
									},
									{
										name: 'lspName',
										label: t('user:status'),
									},
									{
										name: 'rpdNumber',
										label: t('user:depNumeroRevision'),
									},
									{
										name: 'rpdLabel',
										label: t('reports:label'),
									},
									{
										name: 'rpdDescription',
										label: t('user:label'),
									},
									{
										name: 'rpdStart',
										label: t('user:date_debut'),
									},
									{
										name: 'rpdEnd',
										label: t('user:date_fin'),
									},
									{
										name: 'isPrincipalCompany',
										label: t('reports:isPrincipalCompany'),
									},
									{
										name: 'isSubContractingCompany',
										label: t('reports:isSubContractingCompany'),
									},
									{
										name: 'dEStatus',
										label: t('reports:DEStatus'),
									},
									{
										name: 'entAgency',
										label: t('user:Agency'),
									},
									{
										name: 'entName',
										label: t('user:companyName'),
									},
									{
										name: 'entSiret',
										label: t('user:siretNumber'),
									},
									{
										name: 'entSocialReason',
										label: t('user:socialReason'),
									},
								]}
							/>
						)}
					</Grid>
				</Grid>
			</div>
		</>
	)
}

export default PdpList
