import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import DataTable, { getIndexByName } from '../../components/NewDataTable/NewDataTable'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { getDEHistorization } from '../../containers/DataContainer'
import { dateDisplay } from '../../utils/Date'

const useStyles = makeStyles((theme) => ({
	buttonGroup: {
		marginTop: theme.spacing.unit * 2,
		textAlign: 'center',
	},
	title: {
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
		marginTop: theme.spacing.unit * 2,
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
}))

function HistorizationCompaniesRecords() {
	const classes = useStyles()
	const [cookies] = useCookies()
	const { t } = useTranslation('user')
	const [datatable, setDatatable] = useState({ columns: [], data: [], options: {} })
	const ARRAY_KEY_DATE = 'date'
	const [isLoading, setIsLoading] = useState(true)

	const customDate = (value, tableMeta, updateValue) => {
		return dateDisplay(value)
	}

	const getHistorization = () => {
		getDEHistorization(
			cookies[PLATFORM_ID_COOKIE],
			{ nbItemsAsked: 10, pageNumber: 1 },
			(res, err) => {
				if (err) console.error(err)
				if (res) {
					let dateColumnIndex = getIndexByName(res.data.columns, ARRAY_KEY_DATE)
					if (dateColumnIndex !== -1) {
						res.data.columns[dateColumnIndex].options.sortDirection = 'desc'
					}
					setDatatable(res.data)
					setIsLoading(false)
				}
			},
		)
	}

	useEffect(getHistorization, [])

	const stripHtml = (text) => {
		if (!text) return ''
		const noHtml = text.replace(/<[^>]+>/g, '')
		const tempDiv = document.createElement('div')
		tempDiv.innerHTML = noHtml
		return tempDiv.textContent || tempDiv.innerText || ''
	}

	const customColumns = [
		{
			name: 'date',
			component: customDate,
		},
		{
			name: 'commentaireWorkflow',
			component: (value, _tableMeta, _updateValue) => stripHtml(value),
		},
	]

	return (
		<>
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('HistorizationFollowDE')}
			</Typography>
			<hr className={classes.hrRoot} />
			<DataTable
				title={t('Historisation')}
				columns={datatable.columns}
				data={datatable.data}
				options={datatable.options}
				customColumns={customColumns}
				isLoading={isLoading}
				customLabels={[
					{
						name: 'pdpNumero',
						label: t('pdpNumero'),
					},
					{
						name: 'entRaisonSociale',
						label: t('entRaisonSociale'),
					},
					{
						name: 'depNumeroRevision',
						label: t('depNumeroRevision'),
					},
					{
						name: 'statutAvant',
						label: t('statutAvant'),
					},
					{
						name: 'statutApres',
						label: t('statutApres'),
					},
					{
						name: 'userName',
						label: t('userName'),
					},
					{
						name: 'date',
						label: t('date'),
					},
					{
						name: 'commentaireWorkflow',
						label: t('comment_workflow'),
					},
				]}
			/>
		</>
	)
}

export default HistorizationCompaniesRecords
