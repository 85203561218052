import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import classNames from 'classnames'
import { Link } from '@material-ui/core'
import PropTypes from 'prop-types'
import Gauge from '../Gauge/Gauge'
import { useTranslation } from 'react-i18next'
import { bindParams } from '../../utils/Link'
import { v4 as uuid_v4 } from 'uuid'
import ListItemSuperCard from './ListItemSuperCard'

//STYLES
const useStyles = makeStyles(() => ({
	card: {
		color: '#ffffff',
		width: '100%',
		position: 'relative',
	},
	toComplete: {
		backgroundColor: '#4B96D1',
	},
	incomplete: {
		backgroundColor: '#F5960A',
	},
	refused: {
		backgroundColor: '#9D2235',
	},
	validated: {
		backgroundColor: '#72B84D',
	},
	approved: {
		backgroundColor: '#006946',
	},
	cardHeader: {
		color: '#ffffff',
	},
	cardIcon: {
		position: 'absolute',
		bottom: 25,
		right: 25,
	},
	avatar: {
		margin: 10,
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundColor: 'transparent',
		borderRadius: 0,
		width: '60px',
		height: '60px',
	},
	people: {
		backgroundImage: 'url("/icons/people.png")',
	},
	folder: {
		backgroundImage: 'url("/icons/folder.png")',
	},
	health: {
		backgroundImage: 'url("/icons/health.png")',
	},
	unsecuredShield: {
		backgroundImage: 'url("/icons/unsecured-shield.png")',
	},
	listItem: {
		color: '#fff',
	},
	center: {
		textAlign: 'center',
	},
	geconexIcon: {
		position: 'relative',
		top: 5,
		fontWeight: 'bold',
		marginRight: '15px',
		marginLeft: '5px',
	},
}))

const SuperCard = ({
	title,
	subTitle,
	countTitle,
	status = 'default',
	size = 4,
	avatar = 'people',
	count = 0,
	list = [],
	itemspercol,
	maxDisplay = 8,
	linkRedirect,
	keyCard = 'def',
	health = null,
}) => {
	// STYLES
	const classes = useStyles()
	const { t } = useTranslation(['dashboard'])
	const currentDate = new Date().toLocaleString('fr-FR', { timeZone: 'GMT' })

	return (
		<>
			<Grid style={{ display: 'flex' }} item sm={12} md={size}>
				<Card className={classNames(classes.card, classes[status])}>
					<CardHeader
						avatar={<Avatar className={classNames(classes.avatar, classes[avatar])} />}
						action={
							linkRedirect ? (
								<Link href={linkRedirect}>
									<IconButton color="inherit">
										<MoreVertIcon />
									</IconButton>
								</Link>
							) : (
								<></>
							)
						}
						title={title}
						titleTypographyProps={{ color: 'inherit', variant: 'h4' }}
						subheader={subTitle}
						subheaderTypographyProps={{ color: 'inherit' }}
						className={classes.cardHeader}
					/>
					<CardContent>
						{health && (
							<Grid container spacing={16}>
								<Grid item xs={12}>
									<Typography
										component="div"
										variant="body1"
										color="inherit"
										style={{
											display: 'grid',
											gridTemplateColumns: '50% 50%',
										}}
									>
										<div style={{ display: 'inline-block' }}>
											{bindParams(
												t('dashboard:health.userConnected.since'),
												health.userConnected.since.toString(),
											)}
											{health.userConnected.details && (
												<ul>
													{health.userConnected.details.map((value) => {
														return (
															<li key={uuid_v4()}>
																{value.count} {value.title}
															</li>
														)
													})}
												</ul>
											)}
										</div>
										{health.bddPerf && (
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Gauge perf={health.bddPerf} />
											</div>
										)}
										{health.geconex && (
											<div
												style={{
													gridColumn: 'span 2',
												}}
											>
												{Object.entries(health.geconex).map(([key, value]) => {
													const element = health.geconex[key]

													return (
														<div style={{ display: 'inline-block' }}>
															{t(`health.geconex.${key}`)}
															<span
																className={`material-icons ${classes.geconexIcon}`}
																style={{
																	color: element.state ? 'green' : 'red',
																}}
																title={
																	element.lastSuccessSyncDate
																		? new Date(element.lastSuccessSyncDate).toLocaleString(
																				'fr-FR',
																				{
																					timeZone:
																						Intl.DateTimeFormat().resolvedOptions().timeZone,
																				},
																		  )
																		: currentDate
																}
															>
																{element.state ? 'check_circle_outline' : 'block'}
															</span>
														</div>
													)
												})}
											</div>
										)}
									</Typography>
								</Grid>
							</Grid>
						)}
						{!health && (
							<Grid container spacing={16}>
								<Grid item xs={2} className={classes.center}>
									<Typography component="h2" variant="h2" color="inherit">
										<strong>{count}</strong>
									</Typography>
								</Grid>
								<Grid item xs={10}>
									<Typography component="h2" variant="h6" color="inherit">
										{countTitle}
									</Typography>
									{list && (
										<Grid container spacing={16}>
											<Grid item xs>
												<List color="inherit">
													{list.map((value, i) => {
														if (i <= itemspercol - 1)
															return (
																<ListItemSuperCard key={`${keyCard}-${uuid_v4()}`} value={value} />
															)
													})}
												</List>
											</Grid>
											<Grid item xs>
												{list.length >= itemspercol - 1 && (
													<List color="inherit">
														{list.map((value, i) => {
															if (i > itemspercol - 1 && i <= itemspercol * 2 - 1) {
																return (
																	<ListItemSuperCard
																		key={`${keyCard}-${uuid_v4()}`}
																		value={value}
																	/>
																)
															}
														})}
													</List>
												)}
											</Grid>
											<Grid item xs>
												{list.length >= (itemspercol - 1) * 2 && (
													<List color="inherit">
														{list.map((value, i) => {
															if (i > itemspercol * 2 - 1 && i < maxDisplay)
																return (
																	<ListItemSuperCard
																		key={`${keyCard}-${uuid_v4()}`}
																		value={value}
																	/>
																)
															else if (i === maxDisplay)
																return (
																	<ListItem
																		key={uuid_v4()}
																		role={undefined}
																		dense
																		button
																		color="inherit"
																	>
																		<ListItemText
																			primary={<MoreHorizIcon />}
																			primaryTypographyProps={{ color: 'inherit' }}
																		/>
																	</ListItem>
																)
														})}
													</List>
												)}
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
						)}
					</CardContent>
				</Card>
			</Grid>
		</>
	)
}
SuperCard.propTypes = {
	name: PropTypes.string,
	title: PropTypes.string,
	subTitle: PropTypes.string,
	countTitle: PropTypes.string,
	status: PropTypes.string,
	size: PropTypes.number,
	avatar: PropTypes.string,
	count: PropTypes.number,
	list: PropTypes.array,
	itemspercol: PropTypes.number,
	maxDisplay: PropTypes.number,
	linkRedirect: PropTypes.string,
	keyCard: PropTypes.string,
	health: PropTypes.exact({
		userConnected: PropTypes.exact({
			since: PropTypes.string,
			details: PropTypes.arrayOf(
				PropTypes.shape({
					count: PropTypes.number,
					title: PropTypes.string,
				}),
			),
		}),
		bddPerf: PropTypes.number,
		geconex: PropTypes.exact({
			geconexToCow: PropTypes.exact({
				lastSuccessSyncDate: PropTypes.string,
				state: PropTypes.bool,
			}),
			rapsoToGeconex: PropTypes.exact({
				lastSuccessSyncDate: PropTypes.string,
				state: PropTypes.bool,
			}),
			geconex: PropTypes.exact({
				lastSuccessSyncDate: PropTypes.string,
				state: PropTypes.bool,
			}),
		}),
	}),
}
export default SuperCard
